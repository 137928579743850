import LoginLanding from "./LoginLanding.vue";
import HomeLanding from "./home.vue";


export default [
{
    path: "new",
    name:"HomePage",
    component: HomeLanding,
},
 {
    path: "",
    name:"LandingLogin",
    component: LoginLanding,
 },
 
]