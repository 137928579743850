<template>
  <v-app>
    <nav>
      <v-app-bar
        app
        dense
       
        dark
        color="#5596e6"
        class="lighten-8 light-blue darken-4 hidden-sm-and-down"
      >
        <v-app-bar-nav-icon>
          <v-icon v-if="[isModule]==true" @click="mini = !mini" class="white--text" left>mdi-menu</v-icon>
          <img v-if="[isModule]==false" src="../../assets/emblem.png" width="45" alt="Logo" />
          </v-app-bar-nav-icon>
        <v-toolbar-title class="grey--text">
          <span class="white--text text-lowercase">e-</span>
          <span class="white--text font-weight-bold">GA / </span>
          <span class="white--text text-capitalize"
            >Assets Management System</span
          >
          <span class="white--text text-uppercase"> (AMS)</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        
        <v-app-bar-nav-icon>
          <router-link to="/home/landing" style="text-decoration: none">
            <v-icon class="white--text" color="white" left>mdi-home</v-icon>
          </router-link>
        </v-app-bar-nav-icon>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="white--text"
              v-bind="attrs"
              v-on="on"
              slot="activator"
              right
              >mdi-chevron-down</v-icon
            >
            <span style="padding-left: 10px">Test User</span>
          </template>
          <v-list>
            <v-list-item
              v-for="link in links"
              :key="link.name"
              router
              :to="link.route"
            >
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </nav>

    <v-main scroll class="hero">
      <router-view></router-view>
    </v-main>

    <v-footer padless light-blue darken-4>
      <v-col class="text-center" cols="12">
        <h5 style="color: blue">
          Ofisi ya Rais - Menejimenti ya Utumishi wa Umma na Utawala Bora
        </h5>
        <strong>AMS</strong>@{{ new Date().getFullYear() }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>

export default {
 
  name: "LandingPage",
  isModule:false,
  data() {
    return {
      board_account: "/board_account/",
      drawer: true,
      currentBoard: "My Board",
      mini: true,
      links: [
        {
          name: "My Account",
          icon: "mdi-home-outline",
          routinglink: "/HOME",
        },

        {
          name: "Logout",
          icon: "mdi-lock-open",
          routinglink: "/login",
        },
        //         LandingHome

        // LandingLogin
      ],
    };
  },
};
</script>

<style >
.hero {
  /* background: url("../../assets/mtnew.png"); */
  background-image: url("../../assets/bg1.jpg");

  background-attachment: fixed;
  background-size: cover;
  /* height: 100vh; */
}
</style>

<style>
.scroll {
  overflow-y: scroll;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>