import institutionList from  "../institution/InstitutionList.vue";
import InstitutionDashboard from  "../institution/InstitutionDashboard.vue";
import InstitutionClients from  "./InstitutionClients.vue";



export default [
    {
        path: "institutionList",
        name:"institutionList",
        component: institutionList,
    },
    {
        path: "InstitutionDashboard",
        name:"InstitutionDashboard",
        component: InstitutionDashboard,
    },
    {
        path: "InstitutionClients",
        name:"InstitutionClients",
        component: InstitutionClients,
    },
    ]
  
  
