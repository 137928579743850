<template>
  <v-container>
       <InstitutionToolBar/>
      <h2 >{{title}}</h2>
    <v-data-table
      :headers="headers"
      :items="desserts"
      item-key="name"
      class="elevation-1"
      :search="search"
      :custom-filter="filterOnlyCapsText"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search (UPPER CASE ONLY)"
          class="mx-4"
        ></v-text-field>
      </template>
       <template >
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row >
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.name"
                      label="Dessert name"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.calories"
                      label="Calories"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.fat"
                      label="Fat (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.carbs"
                      label="Carbs (g)"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="editedItem.protein"
                      label="Protein (g)"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
    </template>
    <template >
        <v-btn
      class="mx-2"
      fab
      dark
      small
      color="primary"
    >
      <v-icon
      medium 
      @click="navigateToServices"
      >
        mdi-eye
      </v-icon>
      
    </v-btn>
      
      <!-- <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon> -->
    </template>
    
    </v-data-table>
  </v-container>
</template>



<style>
v-btn:hover{
    background-color: rgb(189, 213, 235);
    color: rgb(24, 28, 32);
}
thead .text-start{
    color: rgb(237, 238, 241) !important;
    font-size: medium !important;
    background-color: rgb(77, 109, 170);
}
tr:hover{
    color: rgba(10, 23, 63, 0.938) !important;
    background-color: rgb(180, 187, 201) !important;
    box-shadow: 0px 1px 1px 0px gray ;
    cursor: pointer;
}
 
</style>




<script>
    import InstitutionToolBar from "../../../components/toolBar/storeToolBar.vue"
  export default {
    components: {
        InstitutionToolBar,
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
        title:'Item Category List',
        search: '',
      headers: [
        {
          text: 'Category Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Description', value: 'services' },
        { text: 'Item Type', value: 'g2g' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: '',
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
    }),

    // computed: {
    //   formTitle () {
    //     return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    //   },
    // },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    created () {
      this.initialize()
    },
computed: {
      header () {
        return [
          {
            text: 's/N.#',
            align: 'start',
            // sortable: false,
            value: 'num',
          },
          {
            text: 'Category Name',
            // align: 'start',
            sortable: false,
            value: 'name',
          },
          {
            text: 'Discription',
            value: 'services',
            filter: value => {
              if (!this.calories) return true

              return value < parseInt(this.calories)
            },
          },
          { text: 'Type', value: 'g2g' },
        //   { text: 'Iron (%)', value: 'iron' },
        ]
      },
    },
    
    methods: {
      initialize () {
        this.desserts = [
          { 
            num:1,
            name: 'Electronics',
            services: 'When we speak about assets in accounting, were generally referring to six different categories: current assets, fixed assets, tangible assets, intangible assets, operating assets, and non-operating assets.',
            g2g: 'Non Consumable',
           
          },
          {
            num:2,
            name: 'Faniture',
            services: 'When we speak about assets in accounting, were generally referring to six different categories: current assets, fixed assets, tangible assets, intangible assets, operating assets, and non-operating assets.',

            g2g: 'Non Consumable',
            
          },
          {
            num:3,
            name: 'Hardware',
            services: 'When we speak about assets in accounting, were generally referring to six different categories: current assets, fixed assets, tangible assets, intangible assets, operating assets, and non-operating assets.',

            g2g: 'Non Consumable',
            
          },
          {
            num:4,
             name: 'fixed assets',
             services: 'When we speak about assets in accounting, were generally referring to six different categories: current assets, fixed assets, tangible assets, intangible assets, operating assets, and non-operating assets.',

            G2G: 'Non Consumable',
            
          },
         
        ]
      },
       filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' &&
          value.toString().toLocaleUpperCase().indexOf(search) !== -1
      },

      viewInstitution (item) {
       console.log(item)
      },

     
      navigateToServices(){
          this.$router.push('/survey/responseServices')

      },

    },
  }
</script>