
<template>
<v-container fluid>
 <InstitutionToolBar/>
     <v-btn
      depressed
      color="indigo--text darken-4"
      class="mb-5"
    >
    Number of Active Institution 
      <v-avatar
      class="mx-2 white--text"
      color="primary"
      size="40"
    >2</v-avatar>
    </v-btn>
    <v-divider> </v-divider>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
    >
    
    </v-data-table>
  </v-card>
</v-container>
</template>

<script>
import InstitutionToolBar from "../../components/shared/InstitutionToolBar.vue"

  export default {
     components: {
    InstitutionToolBar,
  },
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'S/N',
            align: 'start',
            filterable: false,
            value: 'number',
          },
          { text: 'Name', value: 'name' },
          { text: 'Join Date', value: 'date' },
        ],
        desserts: [
          {
           number: 1,
           name: 'eGovernment Aauthorityogurt',
           date: "12/07/2022",
          },
          {
           number: 3,
           name: 'eGovernment Authority',
           date: "12/07/2022",
          },
        ],
      }
    },
  }
</script>