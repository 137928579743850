<template>
  <v-container fluid>
    <tool-bar /> 
    <router-view />
 
  </v-container>
</template>

<script>

import ToolBar from "../../../components/shared/UserManagementToolBar.vue";
export default {
  components: { ToolBar },
};
</script>

<style>
</style>