<template>
    <div class="landing-content">
      <InstitutionToolBar/>
    <div class="header-row">
        <div class="header-title">
            <h1 class="text-center">Institution Dashboard </h1>
        </div>
    </div>
    <div class="module-holder">
          <v-card class="module-box" link to="/institution/InstitutionList">
            <v-icon class="mdi mdi-bank-check"></v-icon>
            <span class="mb-name">Total Institution 998</span>
            </v-card>
         <v-card class="module-box" link to="/institution/InstitutionClients">
           <v-icon class="mdi mdi-shield-account-variant"></v-icon>
            <span class="mb-name">Active Institution 0 </span>
          </v-card>

    </div>
     <v-row>
      <v-col cols="12">
        <div class="text-overline mb-4"> Rate of Institution Join In the platform</div>
        <div justify="start" class="text-center pt-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
          <v-btn color="success">excel<v-icon color="white">mdi-download-box</v-icon></v-btn>
          <v-btn color="primary" class="mx-2">CSV<v-icon>mdi-download-box</v-icon></v-btn>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="0"
            max="100"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
        <v-data-table
          :headers="headers"
          :items="desserts"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
        ></v-data-table>
      </v-col>
      
    </v-row>
</div>
</template>

<script>
import InstitutionToolBar from "../../components/shared/InstitutionToolBar.vue"

  export default {
    components: {
      InstitutionToolBar
    },
    data() {
      return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,

      headers: [
        {
          text: "S/N",
          align: "start",
          sortable: false,
          value: "number",
        },
        { text: "Institution Name", value: "institutionName" },
        { text: "Join Date", value: "date" },
      ],

       desserts: [
          {
           number: 1,
           institutionName: 'eGovernment Aauthorityogurt',
           date: "22/06/2022",
          },
          {
           number: 2,
           institutionName: 'eGovernment Aauthority',
            date: "23/06/2022",
          },
          {
           number: 3,
           institutionName: 'eGovernment Aauthority',
           date: "24/06/2022",
          },
          {
           number: 4,
            institutionName: 'eGovernment Aauthority',
           date: "25/06/2022",
          },
        ],
        }

    }
  }
</script>