<template>
  <nav>
    <v-card>
      <v-navigation-drawer
        v-model="drawer"
        :mini-variant.sync="mini"
        permanent
        app
      >
        <div class="text-center indigo--text darken-4">
          <v-img
            src="../../assets/emblem.png"
            max-width="100px"
            class="d-block ml-auto mr-auto mt-3 mb-3"
          ></v-img>
        </div>

        <v-divider></v-divider>
        <!-- v-if="hasAccess(link.permision)" -->
        <v-list dense shaped>
          <v-list-item-group color="#0D47A1">
            <template v-for="link in links">
              <v-list-item link :to="link.routinglink" :key="link.name">
                <v-list-item-icon class="iconclass">
                  <v-icon style="color: #0d47a1">{{ link.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title style="color: #0d47a1">{{
                    link.name
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </nav>
</template>


<script>
// import store from '../../store'
export default {
  // name: "private",

  data() {
    return {
      logeduser: { username: "" },
      // board_account: "/board_account/",
      drawer: true,
      currentBoard: "My Board",
      mini: false,
      links: [
        {
          name: "Dashboard",
          icon: "mdi-home-outline",
          routinglink: "/settings/users/useradasboard",
          permision: "can_manage_users",
        },
        {
          name: "Users and Roles",
          icon: "mdi-account-group-outline",
          routinglink: "/settings/users/listusers",
          permision: "can_manage_users",
        },
      ],
    };
  },

  // mounted(){
  //   const userDetails = store.getters.user
  //   this.logeduser=userDetails
  // }
};
</script>


<style>
</style>