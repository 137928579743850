<template>
    <div class="landing-content">
    <div class="header-row">
        <div class="header-title">
            <h1 class="text-center"> {{institutionName}} </h1>
        </div>
    </div>
    <div class="module-holder">
       
        <v-card class="module-box" routerLink="/incidents/internal-incidents">
            <v-icon class="mdi mdi-desktop-tower-monitor"></v-icon>
            <span class="mb-name">Assets Tracking</span>
        </v-card>
        <v-card class="module-box" routerLink="/problems">
            <v-icon class="mdi mdi-numeric-7-box-multiple-outline"></v-icon>
            <span class="mb-name">Order Management</span>
        </v-card>
        <!-- <router-link to="/store/dashboard">  -->
        <v-card class="module-box" link to="/store/dashboard">
            <v-icon class="mdi mdi-arch"></v-icon>
            <span class="mb-name">Store Management</span>
        </v-card>
        <!-- </router-link> -->
        <v-card class="module-box" routerLink="/settings">
            <v-icon class="mdi mdi-cog-outline"></v-icon>
            <span class="mb-name">Settings</span>
        </v-card>
         
        <v-card class="module-box" link to="/settings/users/useradasboard">
            <v-icon class="mdi mdi-folder-account"></v-icon>
            <span class="mb-name">User Management</span>
        </v-card>
        
            <v-card class="module-box" routerLink="/services">
                <v-icon class="mdi mdi-fingerprint"></v-icon>
                <span class="mb-name">User Attendance</span>
            </v-card>
        <v-card class="module-box" routerLink="/assets">
            <v-icon class="mdi mdi-human-dolly"></v-icon>
            <span class="mb-name">Assets Management</span>
        </v-card>
          <v-card class="module-box" link to="/institution/InstitutionDashboard">
            <v-icon class="mdi mdi-bank-check"></v-icon>
            <span class="mb-name">Institution Management</span>
            
          </v-card>
        
        <v-card class="module-box" routerLink="/incidents/myreported-incident">
            <v-icon class="mdi mdi-shield-account-variant"></v-icon>
            <span class="mb-name">Access Management</span>
        </v-card>
        <!-- End of Individual -->
    </div>
</div>
</template>

<script>
export default {
  name:"HomePage",
  data() {
    return {
      institutionName:"e-Government Authority"
    };
  },
  }
</script>



<style lang="scss" >
  @keyframes bounce {
    15% {
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
    }
    30% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
    50% {
      -webkit-transform: translateY(3px);
      transform: translateY(3px);
    }
    65% {
      -webkit-transform: translateY(-3px);
      transform: translateY(-3px);
    }
    80% {
      -webkit-transform: translateY(2px);
      transform: translateY(2px);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .header-holder {
    position: fixed;
    width: 100%;
    top: 0px;
    background: #00629c;
    height: 40px;
    color: white;
    z-index: 1000;
    box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.3);
    align-items: center;
  }
  .landing-content {
    //min-height: calc(100vh - 40px);
    min-height: 100vh;
    max-width: 100% !important;
    // background: #eaeaea;
    // background-image: url(../assets/bg1.jpg);
    margin-bottom: 40px;
    padding-left: 50px;
    padding-right: 50px;
    overflow: hidden;

    .header-row{
      //margin: 20px auto;
      padding: 15px 0 0 0;
      //display: flex;
      .header-title{
        position: relative;
        h1{
          padding: 20px 0;
          text-align: center;
          font-weight: 900;
          font-size: 2rem;
          color: #19577b;
          letter-spacing: 1.5px;
        }
      }
    }

    .module-holder {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      // margin-top: 60px;
      margin: 15px auto 0px;
      .module-box {
        background: white;
        border-radius: 10px;
        width: 10%;
        min-width: 235px;
        max-width: 270px;
        padding: 10px;
        border: 1px solid #ddd;
        min-height: 130px;
        // box-shadow: 2px 4px 6px #c1c1c1;
        box-shadow: 2px 1px 8px 2px #bbbbbb;
        margin: 20px 10px;
        outline: none;
        cursor: pointer;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        transition: all 0.3s ease 0s;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &:hover {
          background: rgb(240, 242, 245);
          box-shadow: 4px 8px 10px 2px rgba(135, 135, 135, 0.9);
          transition: all 0.5s ease 0s;
          .v-icon {
            animation-name: bounce;
            -moz-animation-name: bounce;
            // width: 50px !important;
            // height: 50px !important;
          .mdi{
            width: 50px !important;
            height: 50px !important;
          }

          }
          .mb-name {
            letter-spacing: 1.5px;
            transform: scale(1.08);
          }
        }

        .v-icon {
          // height: 40px !important;
          // width: 40px !important;
          color: #E65100;
          // color: #616b8f;
          // color: #36366e; 

          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
          -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
          .mdi{
              height: 40px !important;
              width: 40px !important;
            }
        }
        .mb-name {
          margin-top: 25px;
          font-weight: 800;
          letter-spacing: 1.5px;
          color: #00629c;
          transition: all 0.3s ease 0s;
        }
      }
    }
  }

</style>