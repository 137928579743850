<template>
  <v-app>
    <nav>
      <v-app-bar
        dense
        dark
        color="#5596e6"
        class="lighten-8  light-blue darken-4
 hidden-sm-and-down"
      >
        <v-app-bar-nav-icon>
          <img src="../../assets/emblem.png" width="45" alt="Logo" />
        </v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase grey--text">
          <span class="white--text ">ASSET MANAGEMENT SYSTEM </span>
          <span class="white--text font-weight-light"> (AMS)</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <div v-for="link in links" :key="link.name">
          <v-btn text router :to="link.routinglink">
            <v-icon small>{{ link.icon }}</v-icon>
            <span class="mr-1">{{ link.name }}</span>
          </v-btn>
        </div>
        <v-app-bar-nav-icon href="https://www.nbs.go.tz/index.php/en/" target="_blank">
          <img  src="@/assets/census_sw.png" width="60" alt="Logo" />
        </v-app-bar-nav-icon>

      </v-app-bar>
    </nav>

    <v-main scroll class="hero">
      <router-view></router-view>
    </v-main>

    <v-footer padless light-blue darken-4 >
      <v-col class="text-center" cols="12">
        <h5 style="color:blue">Ofisi ya Rais - Menejimenti ya Utumishi wa Umma na Utawala Bora</h5>
          <strong>AMS</strong>@{{ new Date().getFullYear() }}
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "LandingPage",

  data() {
    return {
      board_account: "/board_account/",
      drawer: true,
      currentBoard: "My Board",
      mini: true,
      links: [
        {
          name: "Home",
          icon: "mdi-home-outline",
          // routinglink: "/",
        },
        
        {
          name: "Login",
          icon: "mdi-lock-open",
          routinglink: "/",
        },
//         LandingHome

// LandingLogin
      ],
    };
  },
};
</script>

<style >
.hero {
  background: url("../../assets/mtnew.png");
  background-attachment: fixed;
  background-size: cover;
  /* height: 100vh; */
}
</style>

<style>
.scroll {
  overflow-y: scroll;
}
/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3f51b5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #3f51b5;
}
</style>