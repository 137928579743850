<template>
  <v-app>
    <nav>
      <v-app-bar  color="#e6bc22" dense app >
        <v-app-bar-nav-icon>
          <v-icon @click="mini = !mini" class="white--text" left>mdi-menu</v-icon>
          </v-app-bar-nav-icon>
        <v-toolbar-title class="text-uppercase grey--text">
          <span class="white--text">T-</span>
          <span class="white--text font-weight-light">GSD / </span>
          <span class="white--text">P</span>
          <span class="white--text text-lowercase">ortal</span>
        </v-toolbar-title>

        <v-spacer></v-spacer>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="white--text"
              v-bind="attrs"
              v-on="on"
              slot="activator"
              right
              >mdi-chevron-down</v-icon
            >
            <span style="padding-left: 10px">{{ logeduser.username }}</span>
          </template>
          <v-list>
            <v-list-item
              v-for="link in links"
              :key="link.text"
              router
              :to="link.route"
            >
              <v-list-item-title>{{ link.text }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
              <v-spacer></v-spacer>
      <v-app-bar-nav-icon>
        <router-link to="/" style="text-decoration: none">
          <v-icon class="white--text" color="white" left>mdi-home</v-icon>
        </router-link>
      </v-app-bar-nav-icon>
      </v-app-bar>

      <v-card>
        <v-navigation-drawer
          v-model="drawer"
          :mini-variant.sync="mini"
          permanent
          app
        >
        <div class="text-center indigo--text darken-4" >

        <v-img 
                    src="../../assets/emblem.png"
                    max-width="100px"
                    class="d-block ml-auto mr-auto mt-3 mb-3"
                  ></v-img>
        </div>


          <v-divider></v-divider>

          <v-list dense class="mt-3">
            <v-list-item
              v-for="link in links"
              :key="link.name"
              link
              :to="link.routinglink"
            >
              <v-list-item-icon>
                <v-icon>{{ link.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ link.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <template v-slot:append>
            <div class="pa-2">
              <router-link to="/settings" tag="v-btn">
                <v-btn
                  right
                  fab
                  x-small
                  color="info white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-cog-outline</v-icon></v-btn
                >
              </router-link>
              <v-btn
                right
                fab
                x-small
                color="blue white--text"
                style="margin: 5px"
                ><v-icon>mdi-account-tie</v-icon></v-btn
              >
              <v-btn
                right
                fab
                x-small
                color="pink white--text"
                style="margin: 5px"
                ><v-icon>mdi-power</v-icon></v-btn
              >
            </div>
          </template>
        </v-navigation-drawer>
      </v-card>
    </nav>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MainLayout",

  data() {
    return {
      board_account: "/board_account/",
      drawer: true,
      currentBoard: "My Board",
      mini: false,
      links: [
       {
        name:'Dashboard',
        icon:'mdi-home-outline',
        routinglink:'/survey/dashboard'
    },
    {
        name:'Survey-Responses',
        icon:'mdi-file-table-outline',
        routinglink:'/survey/surveyResponses'
    },
    {
        name:'Approved-Service',
        icon:'mdi-account-group',
        routinglink:'/survey/approvedService'
    },
      ],
    };
  },
};
</script>
