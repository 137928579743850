<template>
 <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-card
          class="d-flex justify-center rounded-xl"
          width="800"
          style="min-width:400px;border-radius:20px !important;min-height:400px"
        >
          <v-row >
            <v-col cols="12" md="6" sm="6" xs="12">
              <div
                class="d-flex align-center justify-center"
                style="background:rgba(240,240,240,0.75);height:100%;border-radius: 20px 0px 0px 20px"
              >
                <div class="emblem-style">
                  <v-img max-height="150" max-width="150" src="../../assets/emblem.png"></v-img>
                </div>
              </div>
            </v-col>
            <v-col cols="12" md="6" sm="6" xs="12">
              <div class="align-items-center justify-center mb-5">
                <v-form class="pt-16 mx-9" @submit.prevent="loginUser">
                  <div class="text-h5"><strong>AMS Login</strong></div>
                  <v-text-field
                    v-model="authDetails.username"
                    class="rounded-lg mt-4 input-style"
                    dense
                    label="Email"
                    name="username"
                    outlined
                    prepend-inner-icon="mdi-email"
                    type="text"
                  ></v-text-field>
                  <v-text-field
                    id="password"
                    v-model="authDetails.password"
                    class="rounded-lg"
                    dense
                    label="Password"
                    name="password"
                    outlined
                    prepend-inner-icon="mdi-key"
                    type="password"
                    v-on:keyup.enter="loginUser"
                  ></v-text-field>
                  <div class="d-flex justify-end w-100">
                    <router-link to="/home/landing" style="text-decoration: none">
                    <v-btn
                      class="white--text rounded-lg mb-3 pa-5 w-100"
                      color="#556fe6"
                      @click="loginUser"
                      style="width:100%"
                      elevation="0"
                      ><v-icon small>mdi-lock-open</v-icon>&nbsp; <strong>Login</strong></v-btn>
                    </router-link>
                  </div>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-layout>
    </v-container>
</template>
<style scoped>
.emblem-style{
  border:4px solid #efefef;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  padding:30px;
  border-radius: 50%;
}
.input[type=text]{
  background: #e6e6e6;

}
</style>
<script>
import { mapActions } from "vuex";
import Vue from "vue";

export default {
  name: "LoginPage",
  props: {
    source: String,
  },
  data() {
    return {
      authDetails: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    ...mapActions(["login"]),
    async loginUser() {
      if (
        this.authDetails.username === "" ||
        this.authDetails.password === ""
      ) {
        Vue.swal({
          toast: true,
          position: "top",
          title: "Empty Fields",
          text: "Please make sure both e-mail and password fields are not empty and try again.",
          timerProgressBar: true,
          timer: 10000,
          icon: "error",
          showConfirmButton: false,
          animation: false,
        });
      } else {
        this.$store.dispatch("userAuthentication", this.authDetails);
      }
    },
  },
  mounted() {
    localStorage.clear();
  },
};
</script>

