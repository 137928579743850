import dashboardPage from "./indexUaa.vue";
import listUser from "./user/listUser.vue";
import listRole from "./role/listRole.vue";
import listDesignation from "./designation/listDesignation.vue";


export default [
{
    path: "",
    name:"Dashboard",
    component: dashboardPage,
},
 {
    path: "user-list",
    name:"UserPage",
    component: listUser,
 },
 {
    path: "role-list",
    name:"RolePage",
    component: listRole,
 },
 {
    path: "list-designation",
    name:"DesignationPage",
    component: listDesignation,
 },
 
 
]