<template>
<v-container fluid>
 <InstitutionToolBar/>
     <v-btn
      depressed
      color="indigo--text darken-4"
      class="mb-5"
    >
    <v-icon>mdi-cached</v-icon>
    sync Institution from GISP
    </v-btn>
    <v-divider> </v-divider>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="desserts"
      :search="search"
    >
    <template v-slot:[`item.actions`]="{  }">

      <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-bind="attrs"
        v-on="on"
        link 
         >  mdi-dots-vertical  </v-icon>
      </template>
      <span>Manage Institution</span>
    </v-tooltip>
      
      
    </template>
    </v-data-table>
  </v-card>
</v-container>
</template>

<script>
import InstitutionToolBar from "../../components/shared/InstitutionToolBar.vue"

  export default {
     components: {
    InstitutionToolBar,
  },
    data () {
      return {
        search: '',
        headers: [
          {
            text: 'S/N',
            align: 'start',
            filterable: false,
            value: 'number',
          },
          { text: 'Name', value: 'name' },
          { text: 'Institution Code', value: 'code' },
          { text: 'Website', value: 'carbs' },
          { text: 'Institution Type', value: 'type' },
          { text: 'Status', value: 'status' },
          { text: 'Actions', value: 'actions' },
        ],
        desserts: [
          {
           number: 1,
           name: 'eGovernment Aauthorityogurt',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 4.0,
            iron: '1%',
          },
          {
           number: 2,
           name: 'eGovernment Aauthority ',
            carbs: "https://www.ega.go.tz/",
            code: "DFG4H",
            type: "PUBLIC",
            status: "Active",
            protein: 4.3,
            iron: '1%',
          },
          {
           number: 3,
           name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 6.0,
            iron: '7%',
          },
          {
           number: 4,
           name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 4.3,
            iron: '8%',
          },
          {
           number: 5,
           name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 3.9,
            iron: '16%',
          },
          {
           number: 6,
           name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 0.0,
            iron: '0%',
          },
          {
           number: 7,
           name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 0,
            iron: '2%',
          },
          {
           number: 8,
           name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 6.5,
            iron: '45%',
          },
          {
           number: 9,
            name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 4.9,
            iron: '22%',
          },
          {
           number: 10,
            name: 'eGovernment Aauthority',
           code: "CDE33F",
           carbs: "https://www.ega.go.tz",
            type: "PUBLIC",
            status: "Active",
            protein: 7,
            iron: '6%',
          },
        ],
      }
    },
  }
</script>