import MainUsers from "./users/MainUsers.vue";

export default [
    {
        path: "users",
        name: "UsersList",
        component: MainUsers,
        children: [
            {
                path:"useradasboard",
                name:"UseraDashboard",
                component: () => import("./users/UsersDashboard.vue")
            },
            {
                path:"listusers",
                name:"ListUsers",
                component: () => import("./users/UserTable.vue")
            }
        ]
    },

   


]