import Vue from "vue";
import VueRouter from "vue-router";
import ExternalLaout from "../components/shared/external.vue";
import LandingLaout from "../components/shared/Landing.vue";
import LayoutShare from "../components/shared/layout.vue";
import authRouter from "../modules/auth/authRouter.js";
import landing from "../modules/landing.js";
import userRouter from "../modules/UAA/uaaRouter.js";
import storeRoute from "../modules/store/storeRoute.js";
import SettingRouter from "../modules/settings/SetttingRouter";
import institutionRouter from "../modules/institution/institutionRouter.js"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "",
    component: ExternalLaout,
    children: [
      ...authRouter,
    ],
  },
  {
    path: "/home",
    name: "",
    component: LandingLaout,
    children: [
      ...landing,
    ],
  },
  {
    path: "/uaa",
    name: "",
    component: LayoutShare,
    children: [
      ...userRouter,
    ],
  },
  {
    path: "/store",
    name: "",
    component: LandingLaout,
    children: [
      ...storeRoute,
    ]
  },
  {
    path: "/settings",
    name: "settings",
    component: LandingLaout,
    children: [
      ...SettingRouter,
    ]
  },
  {
    path: "/institution",
    name: "institution",
    component: LandingLaout,
    children: [
      ...institutionRouter,
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

  