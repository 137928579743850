import dashboardPage from "./indexStore.vue";
import listAssets from "./assets/assetsIndex.vue";
import listCategory from "./category/categoryIndex.vue";
import listType from "./type/typeIndex.vue";


export default [
{
    path: "dashboard",
    name:"Dashboard",
    component: dashboardPage,
},
 {
    path: "assets-list",
    name:"Store",
    component: listAssets,
 },
 {
    path: "category-list",
    name:"Category",
    component: listCategory,
 },
 {
    path: "list-type",
    name:"Type",
    component: listType,
 },
 
 
]